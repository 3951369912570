import * as React from "react";
import {
    Alert,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControl, FormControlLabel, FormGroup, Grid, InputAdornment,
    InputLabel, MenuItem, Select, Switch
} from "@mui/material";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import {toast} from "react-toastify";
import {gql} from "@apollo/client";
import LoadingDialog from "../../components/loadingDialog";
import {mutate} from "../../lib/apollo";
import TextField from "@mui/material/TextField";
import {Code} from "@mui/icons-material";
import {getTrafficTypes} from "./publishersService";
export default function PublisherUpsert (params: {pub: any, setPub: any, reload: any}) {
    const [loading, setLoading] = React.useState(true);
    const [name, setName] = React.useState('' as string);
    const [cpl, setCpl] = React.useState(0);
    const [quality, setQuality] = React.useState(0);
    const [minEpc, setMinEpc] = React.useState(0);
    const [maxEpc, setMaxEpc] = React.useState(0);
    const [ignoreFraudLogix, setIgnoreFraudLogix] = React.useState(false);
    const [trafficTypes, setTrafficTypes] = React.useState([] as string[]);
    const [trafficType, setTrafficType] = React.useState('' as string);
    const [marginLock, setMarginLock] = React.useState(0);
    const [volumeTiers, setVolumeTiers] = React.useState([] as any[]);
    const [minVolume, setMinVolume] = React.useState(0);
    const [maxVolume, setMaxVolume] = React.useState(0);
    const [margin, setMargin] = React.useState(0);

    React.useEffect(() => {
        getTrafficTypes().then((res: any) => {
            setTrafficTypes(res);
            setLoading(false);
        });
    }, []);

    React.useEffect(() => {
        if (params.pub) {
            setName(params.pub.name || '');
            setCpl(params.pub.cpl || 0);
            setQuality(params.pub.quality || 100);
            setMinEpc(params.pub.minEpc || 0);
            setMaxEpc(params.pub.maxEpc || 0);
            setIgnoreFraudLogix(params.pub.ignoreFraudLogix || false);
            setTrafficType(params.pub.trafficType || '');
            setMarginLock(params.pub.marginLock || 0);
            setVolumeTiers((params.pub.volumeTiers || []).map((x: any) => ({min: x.min, max: x.max, margin: x.margin})));
            setMinVolume(0);
            setMaxVolume(0);
            setMargin(0);
        } else {
            setName('');
            setCpl(0);
            setQuality(100);
            setMinEpc(0);
            setMaxEpc(0);
            setIgnoreFraudLogix(false);
            setTrafficType('');
            setMarginLock(0);
            setVolumeTiers([]);
            setMinVolume(0);
            setMaxVolume(0);
            setMargin(0);
        }
    }, [params.pub]);

    const handleClose = (event?: any, reason?: any) => {
        if (reason && reason == "backdropClick")
            return;
        params.setPub(null);
    };

    const handleSubmit = async (event: any) => {
        let q;
        let variables;
        let good = false;

        if (isEdit) {
            q = gql`
                mutation m($id: Int!, $input: PublisherUpdateInput!) {
                    publisherUpdate(id: $id, input: $input)
                }
            `;

            variables = {
                id: params.pub.id,
                input: {
                    name,
                    cpl,
                    quality,
                    minEpc,
                    maxEpc,
                    ignoreFraudLogix,
                    trafficType,
                    marginLock,
                    volumeTiers
                }
            };

            setLoading(true);

            const res = await mutate(q, variables);
            good = res && res.publisherUpdate;
        } else {
            q = gql`
                mutation m($input: PublisherCreateInput!) {
                    publisherCreate(input: $input) {
                        id
                    }
                }
            `;

            variables = {
                input: {
                    name,
                    cpl,
                    quality,
                    minEpc,
                    maxEpc,
                    ignoreFraudLogix,
                    trafficType,
                }
            };

            setLoading(true);

            const res = await mutate(q, variables);
            good = res && res.publisherCreate && res.publisherCreate.id;
        }

        if (good) {
            toast.success(isEdit ? 'Publisher updated successfully' : 'Publisher created successfully');
            setLoading(false);
            handleClose();
            params.reload();
        } else {
            setLoading(false);
        }

    }

    if (!params.pub) return <> </>;

    const isEdit = !!params.pub.id;

    return (
        <>
                <Dialog
                    open={!!params.pub}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    maxWidth={'lg'}
                    fullWidth={true}
                >
                    <DialogTitle id="alert-dialog-title">
                        {isEdit ? `Update Publisher ${params.pub.name}` : 'Create Publisher'}
                    </DialogTitle>
                    <DialogContent>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <TextField
                                    autoComplete={'off'}
                                    margin="dense"
                                    id="name"
                                    label="Name"
                                    type="text"
                                    fullWidth
                                    size={'small'}
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    autoComplete={'off'}
                                    margin="dense"
                                    id="cpl"
                                    label="Cpl"
                                    type="number"
                                    fullWidth
                                    size={'small'}
                                    value={cpl}
                                    onChange={(e) => setCpl(parseFloat(e.target.value || "0"))}
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                    }}
                                    inputProps={{
                                        step: ".01"
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    autoComplete={'off'}
                                    margin="dense"
                                    id="quality"
                                    label="Quality"
                                    type="number"
                                    fullWidth
                                    size={'small'}
                                    value={quality}
                                    onChange={(e) => setQuality(parseFloat(e.target.value || "0"))}
                                    inputProps={{
                                        step: "1"
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    autoComplete={'off'}
                                    margin="dense"
                                    id="minEpc"
                                    label="Min Epc"
                                    type="number"
                                    fullWidth
                                    size={'small'}
                                    value={minEpc}
                                    onChange={(e) => setMinEpc(parseFloat(e.target.value || "0"))}
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                    }}
                                    inputProps={{
                                        step: ".01"
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    autoComplete={'off'}
                                    margin="dense"
                                    id="maxEpc"
                                    label="Max Epc"
                                    type="number"
                                    fullWidth
                                    size={'small'}
                                    value={maxEpc}
                                    onChange={(e) => setMaxEpc(parseFloat(e.target.value || "0"))}
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                    }}
                                    inputProps={{
                                        step: ".01"
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <FormControlLabel control={<Switch checked={ignoreFraudLogix} onChange={(event)=> setIgnoreFraudLogix(event.target.checked)} />} label="Ignore Fraud Logix" />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <FormControl fullWidth size={'small'}>
                                    <InputLabel id="trafficType">Traffic Type</InputLabel>
                                    <Select
                                        labelId="trafficType"
                                        id="trafficType"
                                        value={trafficType}
                                        onChange={(e) => setTrafficType(e.target.value)}
                                        label="Traffic Type"
                                    >
                                        {trafficTypes.map((tt: any) => <MenuItem key={tt} value={tt}>{tt}</MenuItem>)}
                                    </Select>
                                </FormControl>
                            </Grid>

                            {isEdit &&
                                <>
                                <Grid item xs={12} md={6}>
                                <TextField
                                    autoComplete={'off'}
                                    margin="dense"
                                    id="marginLock"
                                    label="Margin Lock"
                                    type="number"
                                    fullWidth
                                    size={'small'}
                                    value={marginLock}
                                    onChange={(e) => setMarginLock(parseFloat(e.target.value || "0"))}
                                    InputProps={{
                                        endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                    }}
                                    inputProps={{
                                        step: ".01"
                                    }}
                                />
                            </Grid>
                                <Grid item xs={12} md={6}>
                                {volumeTiers.map( (vt, i) => (
                                    <Grid container columnSpacing={2} key={i}>
                                        <Grid item xs={3}>
                                        <TextField
                                            autoComplete={'off'}
                                            margin="dense"
                                            id="minVolume"
                                            label="Min"
                                            type="number"
                                            fullWidth
                                            size={'small'}
                                            value={vt.min}
                                            onChange={(e) => {
                                                let vts = [...volumeTiers];
                                                vts[i].min = parseFloat(e.target.value || "0");
                                                setVolumeTiers(vts);
                                            }}
                                            inputProps={{
                                                step: ".01"
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <TextField
                                            autoComplete={'off'}
                                            margin="dense"
                                            id="maxVolume"
                                            label="Max"
                                            type="number"
                                            fullWidth
                                            size={'small'}
                                            value={vt.max}
                                            onChange={(e) => {
                                                let vts = [...volumeTiers];
                                                vts[i].max = parseFloat(e.target.value || "0");
                                                setVolumeTiers(vts);
                                            }}
                                            inputProps={{
                                                step: ".01"
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <TextField
                                            autoComplete={'off'}
                                            margin="dense"
                                            id="margin"
                                            label="Lock"
                                            type="number"
                                            fullWidth
                                            size={'small'}
                                            value={vt.margin}
                                            onChange={(e) => {
                                                let vts = [...volumeTiers];
                                                vts[i].margin = parseFloat(e.target.value || "0");
                                                setVolumeTiers(vts);
                                            }}
                                            InputProps={{
                                                endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                            }}
                                            inputProps={{
                                                step: ".01"
                                            }}
                                        />
                                    </Grid>
                                        <Grid item xs={3} md={3}>
                                            <Button
                                                sx={{marginTop: '10px'}}
                                                variant={"contained"}
                                                color={'error'}
                                                onClick={() => {
                                                    let vts = [...volumeTiers];
                                                    vts.splice(i, 1);
                                                    setVolumeTiers(vts);
                                                }}
                                            >-</Button>
                                        </Grid>
                                    </Grid>
                                ))}
                                    <Grid container columnSpacing={2}>
                                <Grid item xs={3}>
                                    <TextField
                                        autoComplete={'off'}
                                        margin="dense"
                                        id="minVolume"
                                        label="Min"
                                        type="number"
                                        fullWidth
                                        size={'small'}
                                        value={minVolume}
                                        onChange={(e) => setMinVolume(parseFloat(e.target.value || "0"))}
                                        inputProps={{
                                            step: ".01"
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={3} md={3}>
                                    <TextField
                                        autoComplete={'off'}
                                        margin="dense"
                                        id="maxVolume"
                                        label="Max"
                                        type="number"
                                        fullWidth
                                        size={'small'}
                                        value={maxVolume}
                                        onChange={(e) => setMaxVolume(parseFloat(e.target.value || "0"))}
                                        inputProps={{
                                            step: ".01"
                                        }}
                                    />
                                </Grid>
                                    <Grid item xs={3} md={3}>
                                        <TextField
                                            autoComplete={'off'}
                                            margin="dense"
                                            id="margin"
                                            label="Lock"
                                            type="number"
                                            fullWidth
                                            size={'small'}
                                            value={margin}
                                            onChange={(e) => setMargin(parseFloat(e.target.value || "0"))}
                                            InputProps={{
                                                endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                            }}
                                            inputProps={{
                                                step: ".01"
                                            }}
                                        />
                                    </Grid>
                                <Grid item xs={3} md={3}>
                                    <Button
                                        sx={{marginTop: '10px'}}
                                        variant={"contained"}
                                        onClick={() => {
                                            setVolumeTiers([...volumeTiers, {min: minVolume, max: maxVolume, margin}]);
                                            setMinVolume(0);
                                            setMaxVolume(0);
                                            setMargin(0);
                                        }}
                                    >+</Button>
                                </Grid>
                                    </Grid>
                                </Grid>
                            </>
                            }
                        </Grid>
                    </DialogContent>
                    {loading ? <LoadingDialog/> :
                    <DialogActions>
                        <Button sx={{color: 'red'}} onClick={handleClose}>Close</Button>
                        <Button sx={{color: 'green'}} onClick={handleSubmit} autoFocus>
                            {isEdit ? `Update` : 'Create'}
                        </Button>
                    </DialogActions>
                    }
                </Dialog>
        </>
    )
}
