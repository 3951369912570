import {
    Card,
    CardContent,
    CardHeader, FormControl,
    Grid,
    IconButton, Input, InputAdornment,
    Paper, Table,
    TableBody,
    TableCell,
    TableContainer, TableFooter,
    TableHead, TableSortLabel, ToggleButton, ToggleButtonGroup, Tooltip
} from "@mui/material";
import theme2 from "../../lib/theme";
import * as React from "react";
import {gql} from "@apollo/client";
import moment from "moment/moment";
import {query} from "../../lib/apollo";
import Loading from "../../components/loading";
import Box from "@mui/material/Box";
import CachedIcon from "@mui/icons-material/Cached";
import TableHeaderRow from "../../components/TableHeaderRow";
import StripedTableRow from "../../components/StripedTableRow";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import SearchIcon from "@mui/icons-material/Search";
import AccessibleIcon from '@mui/icons-material/Accessible';
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import TableFooterRow from "../../components/TableFooterRow";
import downloadCsv from "../../lib/downloadCsv";
import HttpsIcon from '@mui/icons-material/Https';
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';

const defaultSummary = {q100: 0, cfClicks: 0, cost: 0, cfClicksPer100: 0, cakeClicks: 0, cakeConversions: 0, epc: 0, savingsPercent: 0, missingCreativeCount: 0, revenue: 0, profit: 0, margin: 0};
export default function Publishers(params: {dateRange: any, selectedPublishers: any, reload: any}) {
    const [loading, setLoading] = React.useState(true);
    const [data, setData] = React.useState([] as any[]);
    const [search, setSearch] = React.useState('');
    const [filtered, setFiltered] = React.useState([] as any[]);
    const [summary, setSummary] = React.useState({...defaultSummary} as any);
    const [sort, setSort] = React.useState({column: 'q100', sortAsc: false});
    const [filterLock, setFilterLock] = React.useState('center');

    const reload = async () => {
        setLoading(true);

        const q = gql`
            query q($input: PurchasesByPublisherInput!) {
                purchasesByPublisher(input: $input) {
                    publisherId
                    publisherName
                    minEpc
                    maxEpc
                    savedMaxEpc
                    grade
                    gpa
                    value
                    cost
                    revenue
                    profit
                    margin
                    marginLock
                    savingsPercent
                    q100
                    epc
                    cakeClicks
                    cakeConversions
                    cfClicks
                    cfClicksPer100
                    blockZovio
                    blockSNHU
                    missingCreativeCount
                }
            }
        `;

        const variables = {
            input: {
                startDate: moment(params.dateRange[0]).format("YYYY-MM-DD HH:mm:ss"),
                endDate: moment(params.dateRange[1]).format("YYYY-MM-DD HH:mm:ss"),
                publisherIds: params.selectedPublishers.map((p: any) => p.id),
            }
        }

        const response = await query(q, variables);
        if (response && response.purchasesByPublisher) {
            setData(response.purchasesByPublisher);
            calcFiltered(response.purchasesByPublisher, search, sort, filterLock);
        }

        setLoading(false);
    }

    const calcFiltered = (_data: any[], _search: string, _sort: any, _filterLock: string) => {
        let dat = _data.filter((d: any) => !_search.trim() || d.publisherName.toLowerCase().includes(_search.trim().toLowerCase()) || d.publisherId.toString() === _search.trim());

        if (_filterLock === 'left') {
            dat = dat.filter((d: any) => d.marginLock);
        }
        if (_filterLock === 'right') {
            dat = dat.filter((d: any) => !d.marginLock);
        }

        dat = dat.sort((a: any, b: any) => {
            if (_sort.sortAsc === false) {
                if (a[_sort.column] < b[_sort.column]) {
                    return 1;
                } else {
                    return -1;
                }
            } else {
                if (a[_sort.column] > b[_sort.column]) {
                    return 1;
                } else {
                    return -1;
                }
            }
        });

        setFiltered(dat);

        let sum = {...defaultSummary};
        let value = 0;
        dat.forEach((d: any) => {
            sum.q100 += d.q100;
            sum.cfClicks += d.cfClicks;
            sum.cost += d.cost;
            sum.cakeClicks += d.cakeClicks;
            sum.cakeConversions += d.cakeConversions;
            sum.epc += (d.epc * d.cakeClicks);
            value += d.value;
            sum.missingCreativeCount += d.missingCreativeCount;
            sum.revenue += d.revenue;
            sum.profit += d.profit;
        });
        sum.cfClicksPer100 = sum.q100 ? sum.cfClicks / sum.q100 * 100 : (sum.cfClicks ? 100 : 0 );
        sum.epc = sum.cakeClicks ? sum.epc / sum.cakeClicks : 0;
        sum.savingsPercent = value ? (value - sum.cost) / value * 100 : 0;
        sum.margin = sum.revenue ? sum.profit / sum.revenue * 100 : (sum.profit ? -100 : 0);
        setSummary(sum);
    }

    const doSort = (column: string, defaultAsc: boolean = false) => {
        let s;
        if (sort.column === column) {
            s = {column: column, sortAsc: !sort.sortAsc};
        } else {
            s = {column: column, sortAsc: defaultAsc};
        }
        setSort(s);

        calcFiltered(data, search, s, filterLock);
    }

    async function download() {
        const content = [];
        const header = [];

        header.push('Publisher');
        header.push('Publisher Name');
        header.push('Publisher Id');
        header.push('100s');
        header.push('CF Clicks');
        header.push('CF / 100 %');
        header.push('Cost');
        header.push('Revenue');
        header.push('Profit');
        header.push('Margin');
        header.push('Cake Clicks');
        header.push('Cake Conversions');
        header.push('EPC');
        header.push('Savings %');
        header.push('Min EPC');
        header.push('Max EPC');
        header.push('Grade');
        header.push('GPA');
        header.push('Missing Creatives');
        header.push('Wheelchair');
        header.push('Margin Lock');
        content.push(header);

        let row = [];
        filtered.forEach((pub: any) => {
            row = [];
            row.push(pub.publisherName + ' (' + pub.publisherId + ')');
            row.push(pub.publisherName);
            row.push(pub.publisherId);
            row.push(pub.q100);
            row.push(pub.cfClicks);
            row.push(Math.round(pub.cfClicksPer100 * 10) / 10);
            row.push(Math.round(pub.cost * 100) / 100);
            row.push(Math.round(pub.revenue * 100) / 100);
            row.push(Math.round(pub.profit * 100) / 100);
            row.push(Math.round(pub.margin * 10) / 10);
            row.push(pub.cakeClicks);
            row.push(pub.cakeConversions);
            row.push(pub.epc);
            row.push(Math.round(pub.savingsPercent * 10) / 10);
            row.push(pub.minEpc);
            row.push(pub.maxEpc);
            row.push(pub.grade);
            row.push(pub.gpa);
            row.push(pub.missingCreativeCount);
            pub.savedMaxEpc && pub.savedMaxEpc >= pub.maxEpc ? row.push('Yes') : row.push('No');
            row.push(pub.marginLock);
            content.push(row);
        });

        await downloadCsv('publishers.csv', content);
    }


    React.useEffect(() => {
        if (params.reload) {
            reload().then(() => {});
        }
    }, [params.reload]);

    return (
        <Card sx={{height: '1063px', maxWidth: 'calc(100vw - 10px)'}}>
            <CardHeader title={"Publishers"} sx={{backgroundColor: theme2.palette.primary.main, color: theme2.palette.primary.contrastText, padding: '5px'}}
                        titleTypographyProps={{sx:{fontSize: '18px'}}}
                        action={
                <>
                    <IconButton aria-label="download" sx={{color: theme2.palette.primary.contrastText}} onClick={download}>
                        <FileDownloadIcon />
                    </IconButton>
                    <IconButton aria-label="refresh" sx={{color: theme2.palette.primary.contrastText}} onClick={reload}>
                        <CachedIcon />
                    </IconButton>
                </>
                        }
            />
            <CardContent>
                {loading ? <Box sx={{marginTop: '130px'}}><Loading/></Box> : <>
                    <FormControl variant="outlined" sx={{ m: 1, maxWidth: 190 }} size="small">
                        <Input
                            id="search"
                            type="text"
                            spellCheck="false"
                            placeholder="Search"
                            value={search || ''}
                            onChange={(e: any) => {setSearch(e.target.value); calcFiltered(data, e.target.value, sort, filterLock)}}
                            startAdornment={<InputAdornment position="start"><SearchIcon/></InputAdornment>}
                        />
                    </FormControl>
                    <FormControl
                    sx={{ m: 1 }}
                    >
                        <ToggleButtonGroup
                            size={"small"}
                            value={filterLock}
                            exclusive
                            onChange={(e: any, value) => {setFilterLock(value); calcFiltered(data, search, sort, value)}}
                            aria-label="text alignment"
                        >
                            <ToggleButton value="left" aria-label="left aligned">
                                <LockIcon sx={{color: 'red'}}/>
                            </ToggleButton>
                            <ToggleButton value="center" aria-label="centered">
                                All
                            </ToggleButton>
                            <ToggleButton value="right" aria-label="right aligned">
                                <LockOpenIcon sx={{color: 'green'}}/>
                            </ToggleButton>
                        </ToggleButtonGroup>
                    </FormControl>
                    <TableContainer component={Paper} sx={{maxWidth: 'calc(100vw - 40px)', overflowY: 'auto', maxHeight: '833px'}}>
                        <Table size="small"
                               sx={{
                                   "& .MuiTableRow-root:hover": {
                                       backgroundColor: "primary.light"
                                   }
                               }}
                               stickyHeader={true}
                        >
                            <TableHead>
                                <TableHeaderRow>
                                    <TableCell>
                                        <TableSortLabel
                                            sx={{width: '100%'}}
                                            active={sort.column === 'publisherName'}
                                            direction={sort.column === 'publisherName' && !sort.sortAsc ? 'desc' : 'asc'}
                                            onClick={() => {doSort('publisherName', true)}}
                                        >
                                            Publisher
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell align="right">
                                        <TableSortLabel
                                            sx={{width: '100%'}}
                                            active={sort.column === 'q100'}
                                            direction={sort.column === 'q100' && !sort.sortAsc ? 'desc' : 'asc'}
                                            onClick={() => {doSort('q100', false)}}
                                        >
                                            100s
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell align="right">
                                        <TableSortLabel
                                        sx={{width: '100%'}}
                                        active={sort.column === 'cfClicks'}
                                        direction={sort.column === 'cfClicks' && !sort.sortAsc ? 'desc' : 'asc'}
                                        onClick={() => {doSort('cfClicks', false)}}
                                        >
                                            CF
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell align="right">
                                        <TableSortLabel
                                            sx={{width: '100%'}}
                                            active={sort.column === 'cfClicksPer100'}
                                            direction={sort.column === 'cfClicksPer100' && !sort.sortAsc ? 'desc' : 'asc'}
                                            onClick={() => {doSort('cfClicksPer100', false)}}
                                        >
                                            CF/100
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell align="right">
                                        <TableSortLabel
                                            sx={{width: '100%'}}
                                            active={sort.column === 'cost'}
                                            direction={sort.column === 'cost' && !sort.sortAsc ? 'desc' : 'asc'}
                                            onClick={() => {doSort('cost', false)}}
                                        >
                                            Cost
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell align="right">
                                        <TableSortLabel
                                            sx={{width: '100%'}}
                                            active={sort.column === 'revenue'}
                                            direction={sort.column === 'revenue' && !sort.sortAsc ? 'desc' : 'asc'}
                                            onClick={() => {doSort('revenue', false)}}
                                        >
                                            Rev
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell align="right">
                                        <TableSortLabel
                                            sx={{width: '100%'}}
                                            active={sort.column === 'profit'}
                                            direction={sort.column === 'profit' && !sort.sortAsc ? 'desc' : 'asc'}
                                            onClick={() => {doSort('profit', false)}}
                                        >
                                            Profit
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell align="right">
                                        <TableSortLabel
                                            sx={{width: '100%'}}
                                            active={sort.column === 'margin'}
                                            direction={sort.column === 'margin' && !sort.sortAsc ? 'desc' : 'asc'}
                                            onClick={() => {doSort('margin', false)}}
                                        >
                                            Margin
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell align="right">
                                        <TableSortLabel
                                            sx={{width: '100%'}}
                                            active={sort.column === 'cakeClicks'}
                                            direction={sort.column === 'cakeClicks' && !sort.sortAsc ? 'desc' : 'asc'}
                                            onClick={() => {doSort('cakeClicks', false)}}
                                        >
                                            Clicks
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell align="right">
                                        <TableSortLabel
                                            sx={{width: '100%'}}
                                            active={sort.column === 'cakeConversions'}
                                            direction={sort.column === 'cakeConversions' && !sort.sortAsc ? 'desc' : 'asc'}
                                            onClick={() => {doSort('cakeConversions', false)}}
                                        >
                                            Cake
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell align="right">
                                        <TableSortLabel
                                            sx={{width: '100%'}}
                                            active={sort.column === 'epc'}
                                            direction={sort.column === 'epc' && !sort.sortAsc ? 'desc' : 'asc'}
                                            onClick={() => {doSort('epc', false)}}
                                        >
                                            EPC
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell align="right">
                                        <TableSortLabel
                                            sx={{width: '100%'}}
                                            active={sort.column === 'savingsPercent'}
                                            direction={sort.column === 'savingsPercent' && !sort.sortAsc ? 'desc' : 'asc'}
                                            onClick={() => {doSort('savingsPercent', false)}}
                                        >
                                            Savings
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell align="right">
                                        <TableSortLabel
                                            sx={{width: '100%'}}
                                            active={sort.column === 'minEpc'}
                                            direction={sort.column === 'minEpc' && !sort.sortAsc ? 'desc' : 'asc'}
                                            onClick={() => {doSort('minEpc', false)}}
                                        >
                                            Min
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell align="right">
                                        <TableSortLabel
                                            sx={{width: '100%'}}
                                            active={sort.column === 'maxEpc'}
                                            direction={sort.column === 'maxEpc' && !sort.sortAsc ? 'desc' : 'asc'}
                                            onClick={() => {doSort('maxEpc', false)}}
                                        >
                                            Max
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell align="right">
                                        <TableSortLabel
                                            sx={{width: '100%'}}
                                            active={sort.column === 'gpa'}
                                            direction={sort.column === 'gpa' && !sort.sortAsc ? 'desc' : 'asc'}
                                            onClick={() => {doSort('gpa', false)}}
                                        >
                                            GPA
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell align="right">
                                        <TableSortLabel
                                            sx={{width: '100%'}}
                                            active={sort.column === 'missingCreativeCount'}
                                            direction={sort.column === 'missingCreativeCount' && !sort.sortAsc ? 'desc' : 'asc'}
                                            onClick={() => {doSort('missingCreativeCount', false)}}
                                        >
                                            <sub>cr</sub>No
                                        </TableSortLabel>
                                    </TableCell>
                                </TableHeaderRow>
                            </TableHead>
                            <TableBody>
                                {filtered.map((row: any) => (
                                <StripedTableRow key={row.publisherId}>
                                    <TableCell sx={{minWidth: '200px'}}><a href={`/publishers/${row.publisherId}`} target={"_blank"}>{row.publisherName} ({row.publisherId})</a>
                                        {row.blockZovio && <span style={{textDecoration: 'line-through', color: 'red', marginLeft: '5px'}}>U</span>}
                                        {row.blockSNHU && <span style={{textDecoration: 'line-through', color: 'red', marginLeft: '5px'}}>S</span>}
                                    </TableCell>
                                    <TableCell align="right">{row.q100.toLocaleString(undefined, {maximumFractionDigits: 0, minimumFractionDigits: 0})}</TableCell>
                                    <TableCell align="right">{row.cfClicks.toLocaleString(undefined, {maximumFractionDigits: 0, minimumFractionDigits: 0})}</TableCell>
                                    <TableCell align="right" sx={{backgroundColor: row.cfClicksPer100 >= 300 ? theme2.palette.warning.light : undefined}}>{row.cfClicksPer100.toLocaleString(undefined, {maximumFractionDigits: 1, minimumFractionDigits: 1})}%</TableCell>
                                    <TableCell align="right">${row.cost.toLocaleString(undefined, {maximumFractionDigits: 2, minimumFractionDigits: 2})}</TableCell>
                                    <TableCell align="right">${row.revenue.toLocaleString(undefined, {maximumFractionDigits: 2, minimumFractionDigits: 2})}</TableCell>
                                    <TableCell align="right" sx={{backgroundColor: getProfitColor(row.profit)}}>${row.profit.toLocaleString(undefined, {maximumFractionDigits: 2, minimumFractionDigits: 2})}</TableCell>
                                    <TableCell align="right">{row.margin.toLocaleString(undefined, {maximumFractionDigits: 1, minimumFractionDigits: 1})}%
                                        {!!row.marginLock && <Tooltip title={"Margin Lock: " + row.marginLock + "%"}><HttpsIcon sx={{color: 'red', fontSize: '18px', marginLeft: '5px', verticalAlign: 'text-top'}}/></Tooltip>}
                                    </TableCell>
                                    <TableCell align="right">{row.cakeClicks.toLocaleString(undefined, {maximumFractionDigits: 0, minimumFractionDigits: 0})}</TableCell>
                                    <TableCell align="right">{row.cakeConversions.toLocaleString(undefined, {maximumFractionDigits: 0, minimumFractionDigits: 0})}</TableCell>
                                    <TableCell align="right" sx={{backgroundColor: row.epc > 0 && row.epc <= 0.75 ? theme2.palette.warning.light : undefined}}>${row.epc.toLocaleString(undefined, {maximumFractionDigits: 2, minimumFractionDigits: 2})}</TableCell>
                                    <TableCell align="right">{row.savingsPercent.toLocaleString(undefined, {maximumFractionDigits: 1, minimumFractionDigits: 1})}%</TableCell>
                                    <TableCell align="right">${row.minEpc.toLocaleString(undefined, {maximumFractionDigits: 2, minimumFractionDigits: 2})}</TableCell>
                                    <TableCell align="right" sx={{backgroundColor: row.maxEpc === 0 && row.epc > 0 ? theme2.palette.error.light : undefined}}>{(row.savedMaxEpc && row.savedMaxEpc >= row.maxEpc) && <><AccessibleIcon sx={{color: 'red', fontSize: 'medium', marginLeft: '5px', verticalAlign: 'text-top'}}/> </>}${row.maxEpc.toLocaleString(undefined, {maximumFractionDigits: 2, minimumFractionDigits: 2})}</TableCell>
                                    <TableCell align="right">{row.gpa.toLocaleString(undefined, {maximumFractionDigits: 1, minimumFractionDigits: 1})} ({row.grade})</TableCell>
                                    <TableCell align="right" sx={{backgroundColor: row.missingCreativeCount >= 5 ? theme2.palette.error.light : undefined}}>{row.missingCreativeCount.toLocaleString(undefined, {maximumFractionDigits: 0, minimumFractionDigits: 0})}</TableCell>
                                </StripedTableRow>
                                ))}
                            </TableBody>
                            <TableFooter>
                                <TableFooterRow>
                                    <TableCell>Totals</TableCell>
                                    <TableCell align="right">{summary.q100.toLocaleString(undefined, {maximumFractionDigits: 0, minimumFractionDigits: 0})}</TableCell>
                                    <TableCell align="right">{summary.cfClicks.toLocaleString(undefined, {maximumFractionDigits: 0, minimumFractionDigits: 0})}</TableCell>
                                    <TableCell align="right">{summary.cfClicksPer100.toLocaleString(undefined, {maximumFractionDigits: 1, minimumFractionDigits: 1})}%</TableCell>
                                    <TableCell align="right">${summary.cost.toLocaleString(undefined, {maximumFractionDigits: 2, minimumFractionDigits: 2})}</TableCell>
                                    <TableCell align="right">${summary.revenue.toLocaleString(undefined, {maximumFractionDigits: 2, minimumFractionDigits: 2})}</TableCell>
                                    <TableCell align="right">${summary.profit.toLocaleString(undefined, {maximumFractionDigits: 2, minimumFractionDigits: 2})}</TableCell>
                                    <TableCell align="right">{summary.margin.toLocaleString(undefined, {maximumFractionDigits: 1, minimumFractionDigits: 1})}%</TableCell>
                                    <TableCell align="right">{summary.cakeClicks.toLocaleString(undefined, {maximumFractionDigits: 0, minimumFractionDigits: 0})}</TableCell>
                                    <TableCell align="right">{summary.cakeConversions.toLocaleString(undefined, {maximumFractionDigits: 0, minimumFractionDigits: 0})}</TableCell>
                                    <TableCell align="right">${summary.epc.toLocaleString(undefined, {maximumFractionDigits: 2, minimumFractionDigits: 2})}</TableCell>
                                    <TableCell align="right">{summary.savingsPercent.toLocaleString(undefined, {maximumFractionDigits: 1, minimumFractionDigits: 1})}%</TableCell>
                                    <TableCell align="right"></TableCell>
                                    <TableCell align="right"></TableCell>
                                    <TableCell align="right"></TableCell>
                                    <TableCell align="right">{summary.missingCreativeCount.toLocaleString(undefined, {maximumFractionDigits: 0, minimumFractionDigits: 0})}</TableCell>
                                </TableFooterRow>
                            </TableFooter>
                        </Table>
                    </TableContainer>
                </>}
            </CardContent>
        </Card>
    );
}

function getProfitColor(profit: number) {
    if (profit < - 200) {
        return theme2.palette.error.light
    } else if (profit < 0) {
        return theme2.palette.warning.light
    }
    return undefined;
}
