import * as React from "react";
import DateRange, {RangeToTimes} from "../components/DateRange";
import {gql} from "@apollo/client";
import {query} from "../lib/apollo";
import Loading from "../components/loading";
import {
    Alert, Dialog, DialogActions, DialogContent, DialogContentText,
    DialogTitle,
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    Tooltip
} from "@mui/material";
import TableHeaderRow from "../components/TableHeaderRow";
import StripedTableRow from "../components/StripedTableRow";
import moment from "moment";
import ListIcon from '@mui/icons-material/List';
import Button from "@mui/material/Button";
import CachedIcon from "@mui/icons-material/Cached";
import {Download, SportsScore} from "@mui/icons-material";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import Box from "@mui/material/Box";
import LoadingDialog from "../components/loadingDialog";
import EscalatorWarningIcon from '@mui/icons-material/EscalatorWarning';
import CakeIcon from '@mui/icons-material/Cake';
import DirectionsRunIcon from '@mui/icons-material/DirectionsRun';
import {toast} from "react-toastify";
import ScoreboardIcon from '@mui/icons-material/Scoreboard';

export default function ReportCardsPage() {
    const [loading, setLoading] = React.useState(false);
    const [data, setData] = React.useState([] as any);
    const [notes, setNotes] = React.useState(null as string | null);
    const [dateRange1, setDateRange1] = React.useState([] as any);
    const [dateRange2, setDateRange2] = React.useState([] as any);
    const [scoringOpen, setScoringOpen] = React.useState(null as any | null);

    React.useEffect(() => {
        document.title = "Report Cards | DN";
    }, []);

    React.useEffect(() => {
        reload().then(() => {
        });
        setDateRange1(RangeToTimes("Today"));
        setDateRange2(RangeToTimes("Yesterday"));
    }, []);

    async function reload() {
        setLoading(true);
        const g = gql`
            query {
                reportCards {
                    id
                    date
                    notes
                    grade1Average
                    grade2Average
                    grade3Average
                    grade4Average                    
                }
            }
        `;

        const result = await query(g, null);

        if (result && result.reportCards) {
            setData(result.reportCards);
        }

        setLoading(false);
    }

    async function download(id: number, showKeywords: boolean) {
        const g = gql`
            query q($id: Int!, $showKeywords: Boolean!) {
                reportCardCsv(id: $id, showKeywords: $showKeywords)
            }
        `;

        const result = await query(g, {id, showKeywords});

        if (result && result.reportCardCsv) {
            const element = document.createElement('a');
            const file = new Blob([result.reportCardCsv], {type: 'text/csv'});
            element.href = URL.createObjectURL(file);
            element.download = "reportCard.csv";
            document.body.appendChild(element); // Required for this to work in FireFox
            element.click();
        }
    }
    async function downloadCake(id: number) {
        const g = gql`
            query q($id: Int!, $startDate1: String!, $endDate1: String!, $startDate2: String!, $endDate2: String!) {
                reportCardCakeCsv(id: $id, startDate1: $startDate1, endDate1: $endDate1, startDate2: $startDate2, endDate2: $endDate2)
            }
        `;

        const result = await query(g, {id, startDate1: dateRange1[0], endDate1: dateRange1[1], startDate2: dateRange2[0], endDate2: dateRange2[1]});

        if (result && result.reportCardCakeCsv) {
            const element = document.createElement('a');
            const file = new Blob([result.reportCardCakeCsv], {type: 'text/csv'});
            element.href = URL.createObjectURL(file);
            element.download = "reportCardCake.csv";
            document.body.appendChild(element); // Required for this to work in FireFox
            element.click();
        }
    }

    async function run() {
        const g = gql`
            mutation {
                reportCardTrigger
            }
        `;

        const result = await query(g, null);

        toast.success("Report Card Triggered");
    }

    return (
        <>
            <h2>Report Cards</h2>

            {loading ? <Loading/> :
                <>
                    <Button aria-label="Reload" onClick={() => {
                        reload()
                    }} variant={'contained'} color={'success'} size={"small"} sx={{marginBottom: 1}}>
                        <CachedIcon sx={{fontSize: '20px'}}/>
                    </Button>
                    <Button aria-label="Reload" onClick={() => {
                        run()
                    }} variant={'contained'} color={'info'} size={"small"} sx={{marginBottom: 1, marginLeft: 3}}>
                        <DirectionsRunIcon sx={{fontSize: '20px'}}/>
                    </Button>
                    <br/>
                    <CakeIcon sx={{verticalAlign: 'sub'}}/>
                    &nbsp;1:&nbsp;<DateRange showTime={false} dateRange={dateRange1} setDateRange={setDateRange1}
                                             style={{marginLeft: 'auto'}} placement={'bottomStart'}/>
                    &nbsp;2:&nbsp;<DateRange showTime={false} dateRange={dateRange2} setDateRange={setDateRange2}
                                             style={{marginLeft: 'auto'}} placement={'bottomStart'}/>
                    <br/><br/>
                    {!data || data.length === 0 ? <Alert severity="warning">No results found</Alert> :
                        <>
                            <TableContainer component={Paper}
                                            sx={{maxWidth: 'calc(100vw - 10px)', maxHeight: 'calc(100vh - 300px)'}}>
                                <Table size="small">
                                    <TableHead>
                                        <TableHeaderRow>
                                            <TableCell>
                                                ID
                                            </TableCell>
                                            <TableCell>
                                                Date
                                            </TableCell>
                                            <TableCell>
                                                Gr 1 %
                                            </TableCell>
                                            <TableCell>
                                                Gr 2 %
                                            </TableCell>
                                            <TableCell>
                                                Gr 3 %
                                            </TableCell>
                                            <TableCell>
                                                Gr 4 %
                                            </TableCell>
                                            <TableCell>
                                                Notes
                                            </TableCell>
                                            <TableCell>
                                                Download
                                            </TableCell>
                                        </TableHeaderRow>
                                    </TableHead>
                                    <TableBody>
                                        {data.map((row: any, index: number) => (
                                            <React.Fragment key={row.id}>
                                                <StripedTableRow>
                                                    <TableCell>
                                                        {row.id}
                                                    </TableCell>
                                                    <TableCell>
                                                        {moment(row.date).format('YYYY-MM-DD HH:mm')}
                                                    </TableCell>
                                                    <TableCell>
                                                        {row.grade1Average}%
                                                    </TableCell>
                                                    <TableCell>
                                                        {row.grade2Average}%
                                                    </TableCell>
                                                    <TableCell>
                                                        {row.grade3Average}%
                                                    </TableCell>
                                                    <TableCell>
                                                        {row.grade4Average}%
                                                    </TableCell>
                                                    <TableCell>
                                                        <IconButton onClick={() => setNotes(row.notes)}>
                                                            <ListIcon/>
                                                        </IconButton>
                                                    </TableCell>
                                                    <TableCell>
                                                        <IconButton onClick={() => download(row.id, false)}>
                                                            <FileDownloadIcon/>
                                                        </IconButton>
                                                        <IconButton onClick={() => download(row.id, true)}>
                                                            <EscalatorWarningIcon/>
                                                        </IconButton>
                                                        <IconButton onClick={() => downloadCake(row.id)}>
                                                            <CakeIcon/>
                                                        </IconButton>

                                                        <IconButton onClick={() => setScoringOpen({date: moment(row.date).format('YYYY-MM-DD HH:mm'), grade1Average: row.grade1Average, grade2Average: row.grade2Average, grade3Average: row.grade3Average, grade4Average: row.grade4Average})}>
                                                            <SportsScore/>
                                                        </IconButton>
                                                    </TableCell>
                                                </StripedTableRow>
                                            </React.Fragment>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </>
                    }

                </>

            }
            <Notes notes={notes} setNotes={setNotes}/>
            <Scoring open={scoringOpen} setOpen={setScoringOpen}/>
            </>
    );
}

function Notes({notes, setNotes} : {notes: string | null, setNotes: any}) {
    return (
        <Dialog
            open={!!notes}
            onClose={() => setNotes(null)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {"Notes"}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    <ul style={{fontSize: '14px'}}>
                        {notes && (notes.trim()).split("\n").map((x: string, index: number) => (
                            <li key={index}>{x}</li>
                        ))}
                    </ul>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
            <Button sx={{color: 'green'}} onClick={() => setNotes(null)}>Close</Button>
            </DialogActions>
        </Dialog>
    );
}

function Scoring({open, setOpen} : {open: any, setOpen: any}) {
    if (!open) {
        return <></>
    }
    return (
        <Dialog
            open={!!open}
            onClose={() => setOpen(null)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {"Scoring: " + open.date}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    <b>Group 1: UAGC ({open.grade1Average})%</b><br/>
                    <br/>
                    <b>LV: No (weight=6)</b>
                    <ul>
                        <li>AAA = 5.0+ (points=10)</li>
                        <li>AA = 3.5-4.99 (points=6)</li>
                        <li>A = {(open.grade1Average - .1).toFixed(2)}-3.49 (points=4)</li>
                        <li>B = 1.9-{(open.grade1Average - .11).toFixed(2)} (points=3)</li>
                        <li>C = 0.9-1.89 (points=2)</li>
                        <li>D = 0.1-0.89 (points=1)</li>
                        <li>D = 0 (&lt; 100 Leads) (points=1)</li>
                        <li>F = 0 (100+ Leads) (points=0)</li>
                    </ul>
                    <b>LV: Yes (weight=1)</b>
                    <ul>
                        <li>A = 1+ Apps (points=4)</li>
                        <li>N/A = 0 Apps (omitted)</li>
                    </ul>
                    <hr/>
                    <b>Group 2: Linkout: SNHU</b><br/>
                    <br/>
                    <b>LV: No (weight=4)</b>
                    <ul>
                        <li>A = {(open.grade2Average).toFixed(2)}+ (points=4)</li>
                        <li>B = {(open.grade2Average - 5).toFixed(2)}-{(open.grade2Average - 0.01).toFixed(2)} (points=3)</li>
                        <li>C = {(open.grade2Average - 10).toFixed(2)}-{(open.grade2Average - 5.01).toFixed(2)} (points=2)</li>
                        <li>D = 1+ apps (points=1)</li>
                        <li>D = 0 apps (&lt; 100 Leads) (points=1)</li>
                        <li>F = 0 apps (100+ Leads) (points=0)</li>
                    </ul>
                    <b>LV: Yes (weight=1)</b>
                    <ul>
                        <li>A = {(open.grade2Average).toFixed(2)}+ w/1+Apps (points=4)</li>
                        <li>B = {(open.grade2Average - 5).toFixed(2)}-{(open.grade2Average - 0.01).toFixed(2)} w/2+ Apps (points=3)</li>
                        <li>N/A = 0 Apps (omitted)</li>
                    </ul>
                    <hr/>
                    <b>Group 3: GCU, Studio</b><br/>
                    <br/>
                    <b>LV: No (weight=2)</b>
                    <ul>
                        <li>A = {(open.grade3Average).toFixed(2)}+ (points=4)</li>
                        <li>B = {(open.grade3Average - .7).toFixed(2)}-{(open.grade3Average - 0.01).toFixed(2)} (points=3)</li>
                        <li>C = {(open.grade3Average - 1.2).toFixed(2)}-{(open.grade3Average - 0.71).toFixed(2)} (points=2)</li>
                        <li>D = 0.1-{(open.grade3Average - 1.21).toFixed(2)} (points=1)</li>
                        <li>D = 0 (&lt; 100 Leads) (points=1)</li>
                        <li>F = 0 (100+ Leads) (points=0)</li>
                    </ul>
                    <b>LV: Yes (weight=1)</b>
                    <ul>
                        <li>A = 1+ Apps (points=4)</li>
                        <li>N/A = 0 Apps (omitted)</li>
                    </ul>
                    <hr/>
                    <b>Group 4: H&P POST</b><br/>
                    <br/>
                    <b>LV: No (weight=2)</b>
                    <ul>
                        <li>A = {(open.grade4Average).toFixed(2)}+ (points=4)</li>
                        <li>B = {(open.grade4Average - 1.6).toFixed(2)}-{(open.grade4Average - 0.01).toFixed(2)} (points=3)</li>
                        <li>C = {(open.grade4Average - 2.6).toFixed(2)}-{(open.grade4Average - 1.61).toFixed(2)} (points=2)</li>
                        <li>D = 0.1-{(open.grade4Average - 2.61).toFixed(2)} (points=1)</li>
                        <li>D = 0 (&lt; 100 Leads) (points=1)</li>
                        <li>F = 0 (100+ Leads) (points=0)</li>
                    </ul>
                    <b>LV: Yes (weight=2)</b>
                    <ul>
                        <li>A = {(open.grade4Average).toFixed(2)}+ w/2+ Apps (points=4)</li>
                        <li>A = {(open.grade4Average - 0.6).toFixed(2)}+ w/1+ Apps (points=4)</li>
                        <li>B = &lt; 3.0 w/2+ Apps (points=3)</li>
                        <li>N/A = 0 Apps (omitted)</li>
                    </ul>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button sx={{color: 'green'}} onClick={() => setOpen(null)}>Close</Button>
            </DialogActions>
        </Dialog>
    );
}
